import React from "react";
import {up} from '../../../data/ourpartiners'
import { Link, useLocation } from "react-router-dom";


const University = () => {
  const slug = useLocation()
  return (
    <div className="">
      <div className="flex flex-col items-center  justify-center pt-32">
        <p className="uppercase text-5xl xs:text-2xl font-inriaserif font-semibold">Our Partners</p>
        <div className="flex flex-col justify-center my-2  place-items-center">
        <div className="bg-slate-200 h-[2px] md:w-[400px] xl:w-[400px] sm:w-full xs:w-full"></div>
        <div className="bg-black h-[2px] md:w-[100px] xl:w-[100px] sm:w-full xs:w-full"></div>
        </div>

        <div  className="grid grid-cols-5 xl:grid-cols-6 sm:grid-cols-3 xs:grid-cols-2 gap-x-2 gap-y-8 place-items-center">
          {up.map( (u) => (
            <a href={`${u.link}`} target="_blank" key={u.id} className="m-3 p-3">
              <img src={u.logo} alt="Logo" className="w-[150px] h-[150px] xs:w-[100px] xs:h-[100px] m-2"/>
            </a>
          ))}
        </div>
        

        
      </div>
    </div>
  );
};

export default University;
