import eng from "../assets/images/faculties/eng/2.jpg";
import geo from "../assets/images/faculties/geos/2.jpg";
import ms from "../assets/images/faculties/ms/2.jpg";
import fyImg from "../assets/images/foundation-header.jpg"

import law_dp from "../assets/images/academics/postgraduate/diploma/law.jpg";
import sharia_dp from "../assets/images/academics/postgraduate/diploma/sharia.jpg";
import arbitration_dp from "../assets/images/academics/postgraduate/diploma/arbitration.jpg";

import private_law from "../assets/images/academics/postgraduate/masters/private-law.jpg";
import jurispudence from "../assets/images/academics/postgraduate/masters/islamic-jurispudence.png";
import islamic_studies_msc from "../assets/images/academics/postgraduate/masters/islamic-studies.jpg";

import islamic_sharia from "../assets/images/academics/postgraduate/phd/islamic-sharia.jpg";
import islamic_studies from "../assets/images/academics/postgraduate/phd/islamic-studies.png";
import phd_law from "../assets/images/academics/postgraduate/phd/law.jpg";

import hrImg from "../assets/images/faculties/ec/hrm.jpeg"

import {
  medMessages,
  eduMessages,
  csitMessages,
  engMessages,
  slMessages,
  agrMessages,
  vetMessages,
  hsMessages,
  ecoMessages,
  geoMessages,
  denMessages,
  psMessages,
  msMessages,
  fyMessages
} from "./messages";
import {
  medBackground,
  eduBackground,
  cssBackground,
  engBackground,
  slBackground,
  agrBackground,
  vetBackground,
  hsBackground,
  ecoBackground,
  geoBackground,
  denBackground,
  psBackground,
  msBackground,
  fyBackground
} from "./background";
import {
  medDept,
  eduDept,
  csDept,
  engDept,
  slDept,
  agrDept,
  vetDept,
  hsDept,
  ecoDept,
  geoDept,
  denDept,
  psDept,
  msDept,
} from "./deptaments";
import {
  mdStaff,
  edStaff,
  csStaff,
  engStaff,
  denStaff,
  hsStaff,
  msStaff,
  emStaff,
  ssStaff,
  geoStaff,
  slStaff,
  vetStaff,
  agStaff,
} from "./staff_data";
import {
  medContacts,
  eduContacts,
  csContacts,
  engContacts,
  slContacts,
  agrContacts,
  vetContacts,
  hsContacts,
  ecoContacts,
  geoContacts,
  denContacts,
  psContacts,
  msContacts,
  fyContacts
} from "./contacts";
import { cslabs, sllabs, agrlabs, vetlabs, hstlabs, geolabs } from "./labs";
import {
  detail,
  bfDetail,
  pmDetail,
  paDetail,
  baDetail,
  raDetail,
  vet_Epid_Detail,
  vet_Nutr_detail,
  tropicalDiseaseDetail,
  healthySystemMgtDetail,
  publicHealthNutritionDetails,
  pediatricDetails,
  daf_detail,
  de_detail,
  dpl_detail,
  dhrm_detail,
  dibf_detail,
  general_surgery_detail,
  obs_gyno_detail,
  medical_lab_detail,
  internal_medicine_Details,
  public_health_detail,
  master_private_law_Details,
  master_jurispudence_Details,
  master_public_law_Details,
  hrmDetail,
  MEDetail
} from "./postgraduateDetails";

const backet =
  "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets";
const md = backet + "/others/med.jpg";
const it = backet + "/others/it.jpeg";
const vet = backet + "/others/VET.jpg";
// const eng = backet + "/others/engineering.jpg";

const hs = backet + "/others/HS.jpg";
const edu = backet + "/others/EDUCATION.png";
const ps = backet + "/others/PSIR.jpg";
const agri1 = backet + "/others/AG.jpg";
const sl = backet + "/others/sl.JPG";
const dent = backet + "/others/BU.jpg";
const eco = backet + "/others/economics.PNG";
// const vet =  backet + '/others/dental.jpg'
// const ms = backet + "/others/Marine+Science.jpg";
// const geo = backet + "/others/Geology.jpg";

// const cover = backet + "/others/cover.jpeg";

const med = {
  message: medMessages,
  background: medBackground,
  staff: mdStaff,
  contacts: medContacts,
  dep: medDept,
  labs: "",
};
const ed = {
  message: eduMessages,
  background: eduBackground,
  staff: edStaff,
  contacts: eduContacts,
  dep: eduDept,
  labs: "",
};
const csit = {
  message: csitMessages,
  background: cssBackground,
  staff: csStaff,
  contacts: csContacts,
  dep: csDept,
  labs: cslabs,
};
const engg = {
  message: engMessages,
  background: engBackground,
  staff: engStaff,
  contacts: engContacts,
  dep: engDept,
  labs: "",
};

const shl = {
  message: slMessages,
  background: slBackground,
  staff: slStaff,
  contacts: slContacts,
  dep: slDept,
  labs: sllabs,
};

const agr = {
  message: agrMessages,
  background: agrBackground,
  staff: agStaff,
  contacts: agrContacts,
  dep: agrDept,
  labs: agrlabs,
};

const vett = {
  message: vetMessages,
  background: vetBackground,
  staff: vetStaff,
  contacts: vetContacts,
  dep: vetDept,
  labs: vetlabs,
};

const hsc = {
  message: hsMessages,
  background: hsBackground,
  staff: hsStaff,
  contacts: hsContacts,
  dep: hsDept,
  labs: hstlabs,
};

const ecno = {
  message: ecoMessages,
  background: ecoBackground,
  staff: emStaff,
  contacts: ecoContacts,
  dep: ecoDept,
  labs: "",
};

const geol = {
  message: geoMessages,
  background: geoBackground,
  staff: geoStaff,
  contacts: geoContacts,
  dep: geoDept,
  labs: geolabs,
};

const den = {
  message: denMessages,
  background: denBackground,
  staff: denStaff,
  contacts: denContacts,
  dep: denDept,
  labs: "",
};

const psc = {
  message: psMessages,
  background: psBackground,
  staff: ssStaff,
  contacts: psContacts,
  dep: psDept,
  labs: "",
};

const msc = {
  message: msMessages,
  background: msBackground,
  staff: msStaff,
  contacts: msContacts,
  dep: msDept,
  labs: "",
};

const fy = {
  message: fyMessages,
  background: fyBackground,
  staff: "fyStaff",
  contacts: fyContacts,
  dep: "fyDept",
  labs: "",
};
export const programs = [
  {
    id: 1,
    title: "Undergraduate Programs",
    description: `Benadir University offers a broad range of undergraduate courses suitable for any individual.
       Explore our academic programmes to kick-start your academic journey.\n
       We look forward to welcoming you and showcasing why our university is the perfect choice for your educational journey.
       `,
    lists: [
      {
        id: 1,
        program: "Faculty of Medicine and Surgery",
        slug: "Faculty-of-Medicine-and-Surgery",
        img: md,
        msg: med.message,
        bg: med.background,
        staff: med.staff,
        contacts: med.contacts,
        dep: med.dep,
        labs: md.labs,
        tag: "Faculty of Medicine and Surgery",
      },
      {
        id: 2,
        program: "Faculty of Education",
        slug: "Faculty-of-Education",
        img: edu,
        msg: ed.message,
        bg: ed.background,
        staff: ed.staff,
        contacts: ed.contacts,
        dep: ed.dep,
        labs: ed.labs,
        tag: "Faculty of Education",
      },
      {
        id: 3,
        program: "Faculty of Computer Scicence & IT",
        slug: "Faculty-of-Computer-Scicence-&-IT",
        img: it,
        msg: csit.message,
        bg: csit.background,
        staff: csit.staff,
        contacts: csit.contacts,
        dep: csit.dep,
        labs: csit.labs,
        tag: "Faculty of Computer Science and IT",
      },
      {
        id: 4,
        program: "Faculty of Engineering & Technology",
        slug: "Faculty-of-Engineering-&-Technology",
        img: eng,
        msg: engg.message,
        bg: engg.background,
        staff: engg.staff,
        contacts: engg.contacts,
        dep: engg.dep,
        labs: engg.labs,
        tag: "",
      },
      {
        id: 5,
        program: "Faculty of Sharia and Law",
        slug: "Faculty-of-Sharia-and-Law",
        img: sl,
        msg: shl.message,
        bg: shl.background,
        staff: shl.staff,
        contacts: shl.contacts,
        dep: shl.dep,
        labs: shl.labs,
        tag: "Faculty of Sharia and Law",
      },
      {
        id: 6,
        program: "Faculty of Agriculture",
        slug: "Faculty-of-Agriculture",
        img: agri1,
        msg: agr.message,
        bg: agr.background,
        staff: agr.staff,
        contacts: agr.contacts,
        dep: agr.dep,
        labs: agr.labs,
        tag: "Faculty of Agriculture",
      },
      {
        id: 7,
        program: "Faculty of Veterinary",
        slug: "Faculty-of-Veterinary",
        img: vet,
        msg: vett.message,
        bg: vett.background,
        staff: vett.staff,
        contacts: vett.contacts,
        dep: vett.dep,
        labs: vett.labs,
        tag: "Faculty of Veterinary Science",
      },
      {
        id: 8,
        program: "Faculty of Health Science",
        slug: "Faculty-of-Health-Science",
        img: hs,
        msg: hsc.message,
        bg: hsc.background,
        staff: hsc.staff,
        contacts: hsc.contacts,
        dep: hsc.dep,
        labs: hsc.labs,
        tag: "",
      },
      {
        id: 9,
        program: "Faculty of Economics & Management",
        slug: "Faculty-of-Economics-&-Management",
        img: eco,
        msg: ecno.message,
        bg: ecno.background,
        staff: ecno.staff,
        contacts: ecno.contacts,
        dep: ecno.dep,
        labs: ecno.labs,
        tag: "Faculty of Economics and Management",
      },
      {
        id: 10,
        program: "Faculty of Geoscience and Environment",
        slug: "Faculty-of-Geoscience-and-Environment",
        img: geo,
        msg: geol.message,
        bg: geol.background,
        staff: geol.staff,
        contacts: geol.contacts,
        dep: geol.dep,
        labs: geol.labs,
        tag: "Faculty of Geo-Science and Environment",
      },
      {
        id: 11,
        program: "Faculty of Dentistry",
        slug: "Faculty-of-Dentistry",
        img: dent,
        msg: den.message,
        bg: den.background,
        staff: den.staff,
        contacts: den.contacts,
        dep: den.dep,
        labs: den.labs,
        tag: "Faculty of Dentistry",
      },
      {
        id: 12,
        program: "Faculty of Social Science",
        slug: "Faculty-of-Social-Science",
        img: ps,
        msg: psc.message,
        bg: psc.background,
        staff: psc.staff,
        contacts: psc.contacts,
        dep: psc.dep,
        labs: psc.labs,
        tag: "Faculty of Social Science ",
      },
      {
        id: 13,
        program: "Faculty of Marine Science",
        slug: "Faculty-of-Marine-Science",
        img: ms,
        msg: msc.message,
        bg: msc.background,
        staff: msc.staff,
        contacts: msc.contacts,
        dep: msc.dep,
        labs: msc.labs,
        tag: "Faculty of Marine Science and Nautical Studies",
      },
    ],
  },
];

export const foundation = 
  {
    id: 1,
    program: "Foundation year",
    slug: "Foundation-year",
    img: fyImg,
    msg: fy.message,
    bg: fy.background,
    staff: fy.staff,
    contacts: fy.contacts,
    dep: fy.dep,
    labs: fy.labs,
  }

const im = backet + "/postgrad/internal-medicine.jpg";

export const postgradute = [
  {
    id: 1,
    program: "Master of General Surgery",
    school: "Faculty of Medicine",
    tag: "Master-of-General-Surgery",
    img: backet + "/postgrad/general-surgery.jpg",
    detail: general_surgery_detail,
  },
  {
    id: 2,
    program: "Master of Public health",
    school: "Faculty of Health Science",
    tag: "Master-of-Public-health",
    img: backet + "/postgrad/public-health.jpg",
    detail: public_health_detail,
  },
  {
    id: 3,
    program: "Master of Internal Medicine",
    school: "Faculty of Medicine",
    tag: "Master-of-Internal-Medicine",
    img: backet + "/postgrad/internal-medicine.jpg",
    detail: internal_medicine_Details,
  },
  {
    id: 4,
    program: "Master of Public health in nutrition",
    school: "Faculty of Health Science",
    tag: "Master-of-Public-health-in-nutrition",
    img: backet + "/postgrad/phnutrition.jpeg",
    detail: publicHealthNutritionDetails,
  },
  {
    id: 5,
    program: "Master of Medical Laboratory",
    school: "Faculty of Health Science",
    tag: "Master-of-Medical-Laboratory",
    img: backet + "/postgrad/medicalLabratory.jpg",
    detail: medical_lab_detail,
  },
  {
    id: 6,
    program: "Master of Tropical and Infectious diseases",
    school: "Faculty of Medicine",
    tag: "Master of Tropical and Infectious diseases",
    img: backet + "/postgrad/tropicalAndInfectious.jpeg",
    detail: tropicalDiseaseDetail,
  },
  {
    id: 7,
    program: "Master of Disaster Resillience and Leadership",
    school: "Faculty of Economic & Management",
    tag: "Master of Disaster Resillience and Leadership",
    img: backet + "/postgrad/internal-medicine.jpg",
    detail: detail,
  },
  {
    id: 8,
    program: "Master of Sciences in Banking and Finance",
    school: "Faculty of Economic & Management",
    tag: "Master-of-Sciences-in-Banking-and-Finance",
    img: backet + "/postgrad/bankingAndFinance.jpeg",
    detail: bfDetail,
  },
  {
    id: 9,
    program: "Master of Health Systems Managament",
    school: "Faculty of Health Science",
    tag: "Master of Health Systems Managament",
    img: backet + "/postgrad/healthSystemManagment.jpeg",
    detail: healthySystemMgtDetail,
  },
  {
    id: 11,
    program: "Master of Public law",
    school: "Faculty of Sharea and Law",
    tag: "Master-of-Public-law",
    img: backet + "/postgrad/publicLow.jpg",
    detail: master_public_law_Details,
  },
  {
    id: 12,
    program: "Master in Private Law",
    school: "Faculty of Sharea and Law",
    tag: "Master-in-Private-Law",
    img: private_law,
    detail: master_private_law_Details,
  },
  {
    id: 13,
    program: "Master in Islamic Jurisprudence",
    school: "Faculty of Sharea and Law",
    tag: "Master-in-Islamic-Jurisprudence",
    img: jurispudence,
    detail: master_jurispudence_Details,
  },
  {
    id: 14,
    program: "Master in Islamic Studies",
    school: "Faculty of Sharea and Law",
    tag: "Master-in-Islamic-Studies",
    img: islamic_studies_msc,
    detail: detail,
  },
  {
    id: 15,
    program: "Master of Science in Business Administration",
    school: "Faculty of Economic & Management",
    tag: "Master-of-Science-in-Business-Administration",
    img: backet + "/postgrad/businessAdminstration.png",
    detail: baDetail,
  },
  {
    id: 16,
    program: "Master of Science in Project Management",
    school: "Faculty of Economic & Management",
    tag: "Master-of-Science-in-Project-Management",
    img: backet + "/postgrad/project managment.jpeg",
    detail: pmDetail,
  },
  {
    id: 17,
    program: "Master of Monitoring and Evaluation",
    school: "Faculty of Economic & Management",
    tag: "Master-of-Monitoring-and-evaluation",
    img: backet + "/postgrad/monitoringAndEvaluation.png",
    detail: MEDetail,
  },
  {
    id: 18,
    program: "Master of Science in Public Administration",
    school: "Faculty of Economic & Management",
    tag: "Master-of-Science-in-Public-Administration",
    img: backet + "/postgrad/PublicAdminstration.png",
    detail: paDetail,
  },
  {
    id: 19,
    program: "Master of Animal Nutrition And feed science",
    school: "Faculty of Veterinary",
    tag: "Master-of-Animal-Nutrition-And-feed-science",
    img: backet + "/postgrad/animalNutrition.jpeg",
    detail: vet_Nutr_detail,
  },
  {
    id: 20,
    program: "Master of Veterinary Epidemiology",
    school: "Faculty of Veterinary",
    tag: "Master-of-veterinary-Epidemiology",
    img: backet + "/postgrad/animalEpidomology.jpeg",
    detail: vet_Epid_Detail,
  },
  {
    id: 21,
    program: "Master of Obstetrics and Gynecology",
    school: "Faculty of Medicine",
    tag: "Master of obstetrics and Gynecology",
    img: backet + "/postgrad/ObstetricsAndGynecology.jpeg",
    detail: obs_gyno_detail,
  },
  {
    id: 22,
    program: "Master of Medicine in Pediatrics and Child Health",
    school: "Faculty of Medicine",
    tag: "Master-Pediatrics-and-child-Health",
    img: backet + "/postgrad/pediatric.jpeg",
    detail: pediatricDetails,
  },
  {
    id: 23,
    program: "Master of Science in Research and Applied Statistics",
    school: "Faculty of Economic & Management",
    tag: "Master-of-Science-in-Research-and-Applied-Statistics",
    img: backet + "/postgrad/appliedStatistics.jpeg",
    detail: raDetail,
  },
  {
    id: 24,
    program: "Master of Science in Human Resource Management",
    school: "Faculty of Economic & Management",
    tag: "Master-of-Science-in-Human-Resource-Management",
    img: hrImg,
    detail: hrmDetail,
  },
];

export const PostgraduateDeploma = [
  {
    id: 35,
    program: "Postgraduate Diploma in Procurement and Logistics",
    school: "Faculty of Economic & Management",
    tag: "Postgraduate-Diploma-in-Procurement-and-Logistics",
    img: im,
    detail: dpl_detail,
  },
  {
    id: 36,
    program: "Postgraduate Diploma in Economics",
    school: "Faculty of Economic & Management",
    tag: "Postgraduate-Diploma-in-Economics",
    img: im,
    detail: de_detail,
  },
  {
    id: 37,
    program: "Postgraduate Diploma in Human Resource Management",
    school: "Faculty of Economic & Management",
    tag: "Postgraduate-Diploma-in-Human-Resource-Management",
    img: hrImg,
    detail: dhrm_detail,
  },
  {
    id: 38,
    program: "Postgraduate Diploma in Islamic Banking and Finance",
    school: "Faculty of Economic & Management",
    tag: "Postgraduate-Diploma-in-Islamic-Banking-and-Finance",
    img: backet + "/postgrad/bankingAndFinance.jpeg",
    detail: dibf_detail,
  },
  {
    id: 39,
    program: "Postgraduate Diploma in Accounting and Finance",
    school: "Faculty of Economic & Management",
    tag: "Postgraduate-Diploma-in-Accounting-and-Finance",
    img: im,
    detail: daf_detail,
  },
  {
    id: 40,
    program: "Postgraduate Diploma in Sharia",
    school: "Faculty of Sharea and Law",
    tag: "Postgraduate-Diploma-in-Sharia",
    img: sharia_dp,
    detail: detail,
  },
  {
    id: 41,
    program: "Postgraduate Diploma in Law",
    school: "Faculty of Sharea and Law",
    tag: "Postgraduate-Diploma-in-Law",
    img: law_dp,
    detail: detail,
  },
  {
    id: 42,
    program: "Postgraduate Diploma in Arbitration",
    school: "Faculty of Sharea and Law",
    tag: "Postgraduate-Diploma-in-Arbitration",
    img: arbitration_dp,
    detail: detail,
  },
];

export const phd = [
  {
    id: 75,
    program: "Ph.D. in Law (Public Law, Private Law)",
    school: "Faculty of Sharea and Law",
    tag: "Ph.D.-in-Law-(Public-Law,-Private-Law)",
    img: phd_law,
    detail: detail,
  },
  {
    id: 76,
    program: "Ph.D. in Islamic Sharia",
    school: "Faculty of Sharea and Law",
    tag: "Ph.D.-in-Islamic-Sharia",
    img: islamic_sharia,
    detail: detail,
  },
  {
    id: 77,
    program: "Ph.D. in Islamic Studies",
    school: "Faculty of Sharea and Law",
    tag: "Ph.D.-in-Islamic-Studies",
    img: islamic_studies,
    detail: detail,
  },
];
