import React, { useState } from 'react'
import { FaAngleDown,FaAngleUp } from 'react-icons/fa';
import {
    sem1_2023_2024,
    sem2_2023_2024,
    sem1_2024_2025,
    sem2_2024_2025
} from "../../data/callender"





const backet = 'https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets';


const cover =  backet + '/others/cover.jpeg'

const Calendar = () => {

    const acedemis =  [ 
        { id: 1, year: "2024 - 2025", info: "Academic calendar" , term: [
            {id: 1, sem: "Semester one", calendar: sem1_2024_2025},
            {id: 2, sem: "Semester Two", calendar: sem2_2024_2025},
        ]},
        { id: 2, year: "2023 - 2024", info: "Academic calendar" , term: [
            {id: 1, sem: "Semester one", calendar: sem1_2023_2024},
            {id: 2, sem: "Semester Two", calendar: sem2_2023_2024},
        ]}, 
    ]


  const [collapse, setCollapse] = useState(0);
  const [collapse1, setCollapse1] = useState(true);
  const handleOpen = (value) =>{ 
    setCollapse(value);
    setCollapse1(!collapse1)
  }




  return (
      <div className='bg-[#F2F5F8] flex flex-col '>
        <div className='relative w-full h-[558px] mx-h-[558px]'>
            <img src={cover} className='w-full object-cover h-[558px] mx-h-[558px]' alt="admission"/>
            <div className='absolute top-[80%]  w-full h-[15%] bg-white opacity-50 flex justify-center items-center'> </div>
            <p className=' text-6xl xs:text-4xl  font-bold text-[#0843aafe] text-center uppercase absolute w-full top-[83%]'>
                Academic Calendar
              </p>
           
        </div>

        <div className='flex flex-col justify-center items-center '> 
        {
            acedemis.map( (a,open) => (

          <div key={a.id}  className={"cursor-pointer my-3  md:w-[60%] xl:w-[60%]"}>
            <div onClick={() => handleOpen(open)} className={collapse === open && collapse1 === true? "flex flex-row justify-between py-8 font-semibold text-2xl  border-2 solid  px-10 rounded-lg bg-[#0863AA] text-white": "flex flex-row justify-between py-8 font-semibold text-2xl  border-2 solid   px-10 rounded-lg bg-white"}>
            <p className="hover:underline">
                {a.year} {a.info}
            </p>
            <p className="text-[40px] ">
                {collapse === open && collapse1 === true? <FaAngleUp /> :  <FaAngleDown />}
                
            </p>
            </div>

            {collapse === open && collapse1 === true ?  
            (
              <div className="border solid border-[#0863AA] rounded-lg mb-6 my-3 m-3">
                <div className='border-2 solid border-[#e4e4e4]   md:m-5  p-2 md:p-4 xl:m-5 xl:p-4'>
            {a.term.map(
                t => (
                    <div key={t.id} className=''>
                    <div key={t.id} className="flex  flex-row md:px-10 xl:px-10 justify-start py-1 ">
                        <p  className=" text-left font-sans font-bold text-black text-2xl   py-2 md:px-2 xl:px-2">
                        {t.sem}
                        </p>
                    </div>
                    <div className='flex flex-row justify-start font-bold md:px-10 xl:px-10 my-2 xs:text-sm '>
                    <p className='md:w-[300px] xl:w-[300px] xs:w-[80px] sm:w-[120px]'>Operation</p>
                                    <p className='md:w-[200px] xl:w-[200px] xs:w-[80px] sm:w-[120px] mx-3'>Beginig Date</p>
                                    <p className='md:w-[200px] xl:w-[200px] xs:w-[80px] sm:w-[120px]'>Ending Date</p>
                                    <p className='md:w-[200px] xl:w-[200px] xs:w-[80px] sm:w-[120px]'>Duration</p>
                    </div>
                        {t.calendar.map( c => 
                             (
                                <div key={c.id} className={ c.id%2 === 0 ? 'flex flex-row justify-start text-black xl:px-10 md:px-10' : "flex flex-row justify-start bg-slate-300 md:px-10 xl:px-10"}>
                                    <p className='md:w-[300px] xl:w-[300px] xs:w-[80px] sm:w-[120px]  border-r-2 solid border-[#988d88]'>{c.opp}</p>
                                    <p className='md:w-[200px] xl:w-[200px] xs:w-[80px] sm:w-[120px] mx-3 border-r-2 solid border-[#988d88]'>{c.beginDate}</p>
                                    <p className='md:w-[200px] xl:w-[200px] xs:w-[80px] sm:w-[120px] mx-3 border-r-2 solid border-[#988d88]'>{c.endDate}</p>
                                    <p className='md:w-[200px] xl:w-[200px] xs:w-[80px] sm:w-[120px]'>{c.duration}</p>
                                </div>
                        )
                        
                        )
                        }

                    </div>
                  
                )
            )} 
            </div>
             </div>
              ): ""}


          </div>
            ))
        }
        </div>
      </div>
   
  )
}

export default Calendar