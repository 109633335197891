import FoundationAvatar from "../assets/images/faculties/fy/avatar.jpg";
import dent from "../assets/images/faculties/dstry/dean.png";
import engImage from "../assets/images/faculties/eng/dean_image.jpg";
import Jelani from "../assets/images/faculties/ed/jelani.jpg";

const pro =
  "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets/staff/profesional.jpeg";
const backet =
  "https://bu-page-assets.s3.ap-northeast-1.amazonaws.com/static-assets";
export const medMessages = {
  body: [
    "Dear Prospective Students,",
    "Welcome To Benadir University, Faculty of Medicine, and Surgery!",
    "As Dean of the Faculty of Medicine and Surgery at Benadir University, I would like to share my commitment to providing our students with a comprehensive education and learning environment to reach their full potential in the medical field. I am personally committed to the success of each individual student.Our faculty was set up to bring a different perspective to medical education in Somalia.Our academic staffs are the most qualified in their given field of study and are noted for their stellar teaching abilities, their clinical experience, and their scholarly activities, placing them at the cutting edge of the medical world.",
    "We aim to provide our students with vast resources of scientific and medical knowledge coupled with professionalism and compassion toward patients and the community. Our graduates are distinguished not only by their exemplary patient care but also by their remarkable academic achievements.",
    "Benadir University's Faculty of Medicine and Surgery has been at the forefront of medical innovation and research in the region since its inception in 2002, and it is one of the rare higher education universities that uses both conventional and problem-based learning approaches. Students are encouraged to incorporate self-directed learning, active inquiry, and critical thinking abilities into their regular curriculum.",
    "We accept students who thrive in demanding circumstances and have a strong interest in science and medicine. We hope to see you join us and look forward to meeting you.",
  ],
  img: backet + "/staff/md/tahlil.jpg",
  name: "Dr. Tahliil Abdi Afrah",
  title: "Dean, Faculty of Medicine & Surgery ",
  email: "dean-medicine@bu.edu.so",
  phone: "+252615800101",
};

export const eduMessages = {
  body: [
    "The Faculty of Education takes great pride in the quality of education that is provided to its students. The university staff is excited about helping to prepare our students for the world in which they live. Much of the success that our students have achieved today is the result of the collaborative efforts of our stakeholders. It is the product of several weeks of hard work between board members, teachers, administrators, staff, and community leaders.",
    "This collaboration has not only helped in envisioning the future of the faculty but also in the development of the roadmap that will take us there. By clearly articulating our values, vision, mission, focus areas, and objectives, we can continue in the direction of excellence.",
    "I welcome all prospective students to consider this faculty as their choice. In the next few years, we will introduce greater change and diversity to the faculty that will impact students' learning and make the Faculty of Education at Benadir University one of the top choices for a degree in education.",
    "Due to the lack of educational opportunities in some rural areas of our country, we have made the decision to establish an online e-learning program in 2021–2022, with the goal of spreading knowledge across the entire nation and producing qualified teachers who can share their expertise with our vulnerable community. As a faculty, we were successful in maintaining the virtual learning program.",
  ],
  img: Jelani,
  name: "Prof. Jeilani Kasim Said",
  title: "Dean, Faculty of Education ",
  email: "dean-education@bu.edu.so",
  phone: "+252619279555",
};

export const csitMessages = {
  body: [
    `Dear Academic staff, students and steamed visitors.

        Welcome to faculty of Computer science and Information Technology (CIT) at Benadir university in Mogadishu Somalia, for those seeking undergraduate degree, we provide the Bachelor of computer science and technology. Our faculty comprises a team of distinguished professors, experienced researchers, and dedicated support staff who are committed to nurturing talent, fostering innovations, and promoting excellence in teaching and research. As dean, I am here to support and guide you throughout your academic journey. My aim is to create an inclusive and vibrant learning environment that encourages intellectual curiosity, critical thinking and lifelong learning.  Our professors are professionals and have good experience in various domains like data structure and algorithms, software engineering, AI, Computer network, cloud computing, distributed linear algebra, Database concept and object-oriented programming.

        The Faculty of Computer Science and IT of Benadir University was established in 2003. Since then, our students, faculty, staff, and alumni continue to contribute in significant ways to our reputation as a very strong faculty for the study of computing science. Our Faculty Focuses on Skills with Experience and Quality, which makes it possible for our alumni to become one of the most successful and most demanded Computer Science graduates throughout the world. We also offer Masters Program in close partnership with Makerere University in Uganda.We at the Faculty of Computer Science and IT of Benadir University pride ourselves on many things. We take pride in our history as the second oldest faculty of Benadir University and the first Faculty to produce graduates in BU. We pride on our academic excellence, the relevance of our programs to the country and the beyond, and on the high demand for our graduates nationally and internationally. We take pride as well in our faculty and staff who are working to ensure that your journey with us is memorable and enjoyable.

        Thank you for choosing our faculty. You have decided to invest in us, and we take that very, very seriously. Hold us accountable to meeting your needs as you pursue your dream and we guide you to becoming a member of the IT profession.

        Finally, the next few years will be a time of exciting challenges as well as academic and personal growth for you. We are here to help you overcome the challenges and obtain your degree.

        Sincerely,`,
  ],
  img: backet + "/staff/cs/khalif.jpg",
  name: "Prof. Khaliif Muse Warsame",
  title: "Dean, Faculty of Computer Science and IT",
  email: "dean-csit@bu.edu.so",
  phone: "+252615678538",
};

export const engMessages = {
  body: [
    "Dear Students, Faculty, and Staff, ",
    "It is my pleasure to welcome you all to the Faculty of Engineering at Benadir University. Our faculty is committed to providing high-quality education and research in the fields of civil engineering, electrical and electronic engineering, and architectural engineering. Our faculty members provide students with the knowledge, skills, and experience necessary to become successful engineers and leaders in their respective fields. ",
    "We strive to provide a supportive and inclusive learning environment that fosters creativity, critical thinking, and problem-solving skills. Our programs are designed to meet the needs of the modern engineering profession, with a focus on sustainable and innovative solutions to the challenges facing society today. We aim to produce graduates who are equipped with the skills and knowledge to contribute to the development of their communities and make a positive impact on the world. ",
    "As the Dean of the Faculty of Engineering, I am committed to ensuring that our programs remain at the forefront of engineering education and research. We will continue to invest in our faculty, staff, and students to ensure that we produce graduates who are well-prepared for the challenges and opportunities of the 21st century. I encourage all of you to take advantage of the resources and opportunities available in the Faculty of Engineering at Benadir University and to contribute to our mission of advancing engineering education and research.",
    "Sincerely, ",
  ],
  img: engImage,
  name: "Dr. Yonis Khalif Elmi",
  title: "Dean, Faculty of Engineering",
  email: "dean-engineering@bu.edu.so",
  phone: "+25261-4775135",
};

export const slMessages = {
  body: [
    "Welcome to the faculty of Shari’a and law!",
    "The Faculty of Sharia and Law has been the niche of rights and justice since its inception, spreading awareness of legal culture in the practical reality of Somalia. This prestigious faculty of Sharia and Law at Benadir University was established in 2009 with the aim of excellence in jurisprudential, legal studies, and scientific research.",
    "Since its inception, the faculty has endeavoured to activate its capabilities and abilities to enrich the experiences of its students based on the scientific and intellectual experiences of its professors. This is based on all scientific and technological methods, as well as effective consultative visions for serving all sectors and disciplines.",
    "Our faculty is characterized by a selection of professors of Sharia and law among the members of the teaching staff at the university and an integrated administrative team that implements the strategic plans of the faculty with all proficiency.",
    "The faculty is proud to be one of the faculties that seeks to consolidate the foundations of Sharia and law and to develop the competencies capable of understanding the drafting of the legislation and assimilate its theories in all branches of law and Islamic jurisprudence through lectures, scientific seminars, and conferences held in the field of Sharia and law sciences. In addition, it also launched a postgraduate program in Sharia and Law Sciences through a partnership agreement with the International University of Africa in Sudan in 2016.",
    "Therefore, as the dean of the faculty of Shari’a and law, I take this opportunity to welcome everyone who meets our admission requirements to join us at Benadir University",
    "Sincerely,",
    "",
  ],
  img: backet + "/staff/shl/abdirahman.jpeg",
  name: "Dr. Abdirahman Hassan Omar",
  title: "Dean, Faculty of Shari'a and Law",
  email: "dean-sharialaw@bu.edu.so",
  phone: "+252-6-1-5839898",
};

export const agrMessages = {
  body: [
    `Dear students, faculty, and visitors,

I am delighted to welcome you to the Faculty of Agriculture at Benadir University. As the Dean of this esteemed institution, it is my pleasure to extend my warmest greetings and share with you the essence of our academic community.

At the Faculty of Agriculture, we pride ourselves on our commitment to excellence in education, research, and community engagement. Our dedicated faculty members bring a wealth of knowledge and experience to the classroom, ensuring that our students receive a well-rounded education that prepares them for the challenges of the ever-evolving agricultural landscape.

Our focus extends beyond imparting theoretical knowledge. We believe in fostering a practical learning environment where students can develop hands-on skills through experiential learning opportunities. By combining classroom instruction with fieldwork and internships, we aim to equip our graduates with the necessary skills and competencies to thrive in their future careers.

As a faculty, we are dedicated to advancing agricultural research and innovation. Our faculty members are actively engaged in cutting-edge research projects that address the pressing issues facing the agricultural sector today, such as sustainable farming practices, food security, and climate change mitigation. We encourage our students to actively participate in research activities, nurturing their curiosity and critical thinking abilities.

Furthermore, community engagement lies at the core of our philosophy. We believe in the transformative power of agriculture to uplift communities and contribute to sustainable development. Through outreach programs, partnerships with local farmers, and collaborations with relevant stakeholders, we aim to make a positive impact on society by addressing real-world challenges and promoting agricultural development.

I invite you to explore our website and discover the diverse range of programs, research initiatives, and resources available at the Faculty of Agriculture. Whether you are a prospective student, a current student, or an interested individual, our website serves as a gateway to the wealth of opportunities and information that our faculty has to offer.
Thank you for your interest in the Faculty of Agriculture at Benadir University. Together, let us embark on a journey of learning, discovery, and innovation as we strive to make a difference in the field of agriculture and contribute to a sustainable future.

Warm regards,`,
  ],
  img: pro,
  name: "Prof Mohamud Mohamed Mohamud",
  title: "Dean of the Faculty of Agriculture",
  email: "Dean-agriculture@bu.edu.so",
  phone: "+252615727870",
};

export const vetMessages = {
  body: [
    "Dear Colleagues,",
    "Welcome to the faculty of veterinary science at Benadir University!",
    "The Faculty of Veterinary Science is unique and it is one of the nation’s top veterinary and biomedical colleges in Somalia, and thus has an essential role in training veterinary and para-veterinary professionals.",
    "Our graduates and research programs are vital in enhancing public and animal health, promoting biosecurity and welfare, and ensuring our productive animals are safe and sound. ",
    "Graduates from the college go on to be leading clinicians, diagnosticians, researchers, and professors across the Somalia.  The faculty’s teachers and teaching programs have achieved acclaim for their quality and effectiveness.",
    "Our programs are distinctive because they engage students in active, practical learning and we developed essential well updated integrated curriculum.",
    "We look forward to welcoming you as a new student to our university.",
  ],
  img: backet + "/staff/vet/arif.jpg",
  name: "Dr. Abdi Arif Gure",
  title: "Dean of the Faculty of Veterinary",
  email: "dean-veterinary@bu.edu.so",
  phone: "+252 61 9443743",
};

export const hsMessages = {
  body: [
    "Dear students,",
    "I am very glad to welcome you to our faculty. To start with, I'd like to wish you a year full of hope, health and success. I would also like to extend a warm welcome to all students, faculty and those visiting our BU website.",
    "This year, we are pleased and excited to be greeting our new valuable students into our BU family. I'd like to emphasize how rightful you are to be excited and happy to be a student of Benadir University.",
    "The major aim of our faculty is to foster our students as professional individuals that take part in multidisciplinary practice with creative thinking, ethical values, a moral responsibility for society, sharing personality, integrative thinking, a passion to learn, produce research and adapt to innovation. ",
    "I take pride in the central role which the Faculty of Health Sciences plays in fulfilling Benadir University’s mission and course of action as it seeks to contribute and improve the health system in Somalia. I am very proud of our accomplishments and am particularly pleased that the faculty has earned its academic accreditation. I am also very proud of the steady growth and progress we have achieved, as this inspires all of us to work harder and exert more tireless efforts in the service of our country, humanity, and knowledge.",
    "Once again, I wish all our student’s success in their studies and I urge them to try their best to acquire the knowledge and academic skills crucial for success in their future lives.",
    "Thank you for considering Benadir University Faculty of Health Sciences as you pursue your path to a rewarding career in the health sciences.",
    "I encourage you to explore our BU website, contact us with your questions and thoughtfully consider advancing your education at Benadir University.",
  ],
  img: backet + "/staff/hs/hayle.jpg",
  name: "Dr. Abdullahi Ali Hayle",
  title: "Dean Faculty of Health Science",
  email: "dean-healthscience@bu.edu.so",
  phone: "+25261-5925640",
};

export const ecoMessages = {
  body: [
    "Welcome to the Faculty of Economics and Management at Benadir University!",
    "If you are a student planning to pursue a career in economics, management, or finance, I encourage you to start your education at the Faculty of Economics and Management (FEMA). We offer you a wide range of competitive economics and business programs that will contribute to your success in the future, both at the undergraduate and postgraduate levels.",
    "Our academic and professional staff provide excellent service and support to our students. The faculty aims to produce graduates who have combined theory and practice in the realities of business life with strong academic backgrounds.",
    "At FEMA, we are committed to delivering both enriching and transnational learning experiences. We ensure that our programs and learning outcomes are measured and met. We also constantly review our curriculum to make it relevant to the real world of business and the changing global economy.",
    "FEMA benchmarks against world-class universities and closely collaborates with industries to extend the students’ learning opportunities beyond classes that are critical for their future professional success. ",
    "We are proud to say that you will get the best education in a caring environment that places our “students as clients” first. ",
    "We look forward to welcoming you to Benadir University's Faculty of Economics and Management (FEMA).",
  ],
  img: backet + "/staff/em/Ibrahim.jpg",
  name: "Dr. Ibrahim Omar Ahmed",
  title: "Dean, Faculty of Economics and Management",
  email: "dean-fema@bu.edu.so",
  phone: "+252618438870",
};

export const geoMessages = {
  body: [
    `Dear Students,

    Welcome to the Faculty of Geoscience and Environment at Benadir University.

    As the dean of this faculty, I am extremely proud of the rich tradition of providing practical, experiential-based geoscience studies that our faculty has upheld since our establishment. 

    Benadir University holds the distinction of being the first university in Somalia to establish a geoscience and environment department after the civil war.

    Our faculty’s activities primarily revolve around the study of Earth science, with a particular focus on the exploration and extraction of natural resources. This endeavour is of utmost importance in an era of rapid industrialization. We strive to enhance our understanding of our planet's history and future, the energy and resources that sustain our society, the risks posed by geological hazards to the growing population, the impact of a changing climate, and the challenges of maintaining a sustainable living environment.

    Over the years, our department has produced numerous qualified specialists who are currently making valuable contributions in their respective profession. 

    I extend an invitation to you to join us on a fascinating adventure to learn more about our planet Earth and to help preserve it for future generations. I hope to have the opportunity to welcome you personally to our faculty, where we are committed to providing you with the best and most comprehensive education to equip you for a successful professional career.`,
  ],
  img: backet + "/staff/gs/gobdoon.jpg",
  name: "Eng. Mohamed Abukar Haji Goobdoon",
  title: "Dean, Faculty of Geoscience & Envoirement",
  email: "dean-geoscience@bu.edu.so",
  phone: "+252617496642",
};

export const denMessages = {
  body: [
    "Dear esteemed colleagues and prospectus students,",
    "I am honored to serve as the Dean of the Faculty of Dentistry at Benadir University. It is my distinct pleasure to welcome you to our prestigious institution, where we are committed to providing exceptional education and training to prepare our students to become leaders in the field of dentistry.",
    "Our faculty is comprised of highly skilled and experienced professionals who are dedicated to ensuring that our students receive the best possible education and training. We are committed to providing a supportive and inclusive learning environment that fosters intellectual curiosity, critical thinking, and a lifelong passion for learning.",
    "As dean, I am committed to ensuring that our faculty remains at the forefront of dental education and research. We will continue to invest in cutting-edge technology and innovative teaching methodologies to ensure that our students are equipped with the skills and knowledge needed to succeed in their careers and have a competitive advantage in the dental care industry.",
    "I invite you to join us on this exciting journey as we strive to make a positive impact on the world through education, research, and community outreach. Together, we can achieve our vision of excellence in dental education and training at Benadir University.",
    "Sincerely,",
  ],
  img: dent,
  name: "Dr. Abdinur Hussein Mohamed",
  title: "Dean, Faculty of Dentistry",
  email: "dean-dentistry@bu.edu.so",
  phone: "+252615746060",
};

export const psMessages = {
  body: [
    "Salam,",
    "Welcome to the faculty of Social Science!",
    "I am delighted to address you as the Dean of the Faculty of Social Science and extend my warmest greetings to each and every one of you. It is with great pride and enthusiasm that I embark on this journey alongside such a distinguished group of educators, researchers, and professionals dedicated to the study of human society and behaviour.",
    "The faculty of social science is one of the youngest faculties at the prestigious Benadir University. At this time, Somalia and Somalis understand the value of stable and harmonized political and social system based on democracy, rule of law, freedom and overall justice, grounded by our culture, our religious value and believe. To this end, it is important to understand and analyse all sort of knowledge (past and present) and the new technology systems from East and the West to elevate peoples understanding and how to implement a political and social system that guides and develop the people’s knowledge of understanding of what political administration system is. It was under these circumstances are the foundation of the Faculty of social science at Benadir University. As an institution our main goals are to enlighten, teach and to facilitate the knowledge it requires that the young students, lectures of today could acquire the expertise and the tools to understand and to analyse and thereafter to overcome todays complicated world issues and also to make their mark in our modern world.",
    "I am honoured to serve as your Dean, and I am confident that together we will continue to uphold the values of the Faculty of Social Science and make meaningful contributions to our society. Let us embrace the challenges and opportunities that lie ahead with enthusiasm, resilience, and a shared commitment to excellence.",
    "Wishing you a successful and fulfilling academic year ahead",
    "Sincerely,",
  ],
  img: backet + "/staff/ss/Harun.jpg",
  name: "Mr. Harun Abdi Kulmiye",
  title: "Dean, Faculty of Social Science",
  email: "dean-ss@bu.edu.so",
  phone: "+252612339335",
};

export const msMessages = {
  body: [
    "Welcome to the Faculty of Marine Science and Nautical Studies at Benadir University. It is my immense pleasure to serve as faculty dean and to be able to collaborate with a team of staff who have a strong commitment to the training of a new generation of scientific professionals.",
    "Our faculty programs aim to foster and produce highly skilled experts and global leaders with specialized knowledge and professional skills concerning the sea who can contribute to the development of maritime society both within and outside of Somalia.",
    "At our faculty, we work to educate, research, and do our best to contribute to society towards the creation of a sustainable future in maritime society and new maritime professionals for future generations. ",
    "We look forward to welcoming you to the Faculty of Marine Science and Nautical Studies at Benadir University.",
  ],
  img: backet + "/staff/ms/meyre.jpg",
  name: "Dr. Hassan Mohamud Nur (Meyre)",
  title: "Dean, Faculty of Marine Science,and nautical studies",
  email: "Dean-marincescience@bu.edu.so",
  phone: "+252615507711",
};

export const fyMessages = {
  body: [
    "As the Dean of the Foundation Year, I want to emphasize how committed I am to providing students with a life-changing educational experience. Our goal is to provide a vibrant, welcoming learning environment where students from all backgrounds can succeed.",
    "Our goal is to give students a solid academic foundation, necessary life skills, and a desire to learn continuously throughout their lives. Our goal is to equip our students not only for a smooth transition into Higher education but also for responsible and moral engagement in a world that is changing all the time.",
    "Our goal in foundational education is to set the bar for quality through research-driven initiatives, collaborative partnerships, and continual progress. We ensure that our students are equipped to face the problems of the future by encouraging innovation and critical thinking in them.",
  ],
  img: FoundationAvatar,
  name: "Eng. Ismail Mohamed Abdiraham",
  title: "Dean of the Foundation Year",
  email: "dean-foundation@bu.edu.so",
  phone: "+252615561224",
};
