export const sem1_2023_2024 = [
            {id: 1, opp: "Classes start",beginDate:"9 sept, 2023",endDate: "03 nov, 2023", duration: "8 weeks"},
            {id: 2, opp: "Thesis defense period",beginDate:"5 august, 2023",endDate: "27 oct, 2023", duration: ""},
            {id: 3, opp: "Graduation day",beginDate:"15 oct, 2023",endDate: "15 oct, 2023", duration: ""},
            {id: 4, opp: "Mid term exam",beginDate:"04 nov, 2023",endDate: "10 nov, 2023", duration: "1 week"},
            {id: 5, opp: "Class resume",beginDate:"11 nov, 2023",endDate: "29 Dec, 2023", duration: "7 weeks"},
            {id: 6, opp: "Final exam period",beginDate:"30 dec, 2023",endDate: "12 jan, 2024", duration: "2 weeks"},
            {id: 7, opp: "Semester vacation",beginDate:"13 jan, 2024",endDate: "03 nov, 2024", duration: "2 weeks"},
            {id: 8, opp: "Submision exam Result sheet",beginDate:"13 Jan, 2024",endDate: "19 Jan, 2024", duration: "1 week"},
            {id: 9, opp: "Final exam data entry",beginDate:"20 jan, 2024",endDate: "26 jan, 2024", duration: "1 week"},
            {id: 10, opp: "Aproving exam result on academic counsel",beginDate:"29 jan, 2024",endDate: "", duration: ""},
            {id: 11, opp: "Exam aproval notification",beginDate:"30 jan, 2024",endDate: "", duration: ""},
            {id: 12, opp: "Re-exam period",beginDate:"03 Feb, 2024",endDate: "09 Feb, 2024", duration: "1 week"},
            {id: 13, opp: "Class start semester 2",beginDate:"10 Feb, 2024",endDate: "", duration: ""}
        ]
 export const  sem2_2023_2024 = [
            {id: 1, opp: "Classes start",beginDate:"17 Feb, 2024",endDate: "19 April, 2024", duration: "7 weeks"},
            {id: 2, opp: "Eid Al-Fitri vacation",beginDate:"30 March, 2024",endDate: "12 Apr, 2024", duration: "2 weeks"},
            {id: 3, opp: "Mid term exam",beginDate:"20 Apr, 2024",endDate: "26 Apr, 2024", duration: "1 week"},
            {id: 4, opp: "Classes resume",beginDate:"27 Apr, 2024",endDate: "14 June, 2024", duration: "7 weeks"},
            {id: 5, opp: "Eid Al-adha vacation",beginDate:"15 June, 2024",endDate: "29 June, 2024", duration: "2 weeks"},
            {id: 6, opp: "Final exam period",beginDate:"29 June, 2024",endDate: "05 July, 2024", duration: "1 weeks"},
            {id: 7, opp: "Oral exam period",beginDate:"06 July, 2024",endDate: "12 July, 2024", duration: "1 weeks"},
            {id: 8, opp: "Semester vacation",beginDate:"13 July, 2024",endDate: "06 Sep, 2024", duration: "8 weeks"},
            {id: 9, opp: "Submision exam Result sheet",beginDate:"13 July, 2024",endDate: "19 July, 2024", duration: "1 week"},
            {id: 10, opp: "Final exam data entry",beginDate:"20 July, 2024",endDate: "26 July, 2024", duration: "1 week"},
            {id: 11, opp: "Aproving exam result on academic counsel",beginDate:"29 July, 2024",endDate: "", duration: ""},
            {id: 12, opp: "Exam aproval notification",beginDate:"30 July, 2024",endDate: "", duration: ""},
            {id: 13, opp: "Thesis Defense Period",beginDate:"05 Aug, 2024",endDate: "26 Oct, 2024", duration: ""},
            {id: 14, opp: "Re-exam period",beginDate:"24 Aug, 2024",endDate: "30 Aug, 2024", duration: "1 week"},
            {id: 15, opp: "Class start 2024 - 2025",beginDate:"14 Sep, 2024",endDate: "", duration: ""},
]

export const  sem1_2024_2025 = [
    {id: 1, opp: "Start of the first semester",beginDate:"14 Sep, 2024",endDate: "31 Jan, 2025", duration: ""},
    {id: 2, opp: "Classes begin",beginDate:"14 Sep, 2024",endDate: "8 Nov, 2024", duration: "8 weeks"},
    {id: 3, opp: "Mid term exam",beginDate:"09 Nov, 2024",endDate: "15 Nov, 2024", duration: "1 week"},
    {id: 4, opp: "Classes resume",beginDate:"16 Nov, 2024",endDate: "10 Jan, 2025", duration: "7 weeks"},
    {id: 5, opp: "Final exam period",beginDate:"11 Jan, 2025",endDate: "17 Jan, 2025", duration: "1 week"},
    {id: 6, opp: "University transfere application begin",beginDate:"18 Jan, 2025",endDate: "13 Feb, 2025", duration: "4 weeks"},
    {id: 7, opp: "Oral exam period",beginDate:"18 Jan, 2025",endDate: "25 Jan, 2025", duration: "1 week"},
    {id: 8, opp: "Publication of exam results",beginDate:"24 Jan, 2024",endDate: "", duration: ""},
    {id: 9, opp: "Re-exam period",beginDate:"25 Jan, 2025",endDate: "31 Jan, 2025", duration: "1 week"},
    {id: 10, opp: "Publication of re-exam results",beginDate:"01 Feb, 2025",endDate: "", duration: ""},
    {id: 11, opp: "Break begin",beginDate:"01 Feb, 2025",endDate: "14 Feb, 2025", duration: "2 weeks"},
]

export const  sem2_2024_2025 = [
    {id: 1, opp: "Start of the second semester",beginDate:"15 Feb, 2025",endDate: "04 July, 2025", duration: ""},
    {id: 2, opp: "Classes begin",beginDate:"15 Feb, 2025",endDate: "21 March, 2025", duration: "5 weeks"},
    {id: 3, opp: "Eid Al-Fitri vacation",beginDate:"22 March, 2025",endDate: "04 Apr, 2025", duration: "2 week"},
    {id: 4, opp: "Classes resume",beginDate:"05 Apr, 2025",endDate: "18 Apr, 2025", duration: "3 weeks"},
    {id: 5, opp: "Mid term exam",beginDate:"19 Apr, 2025",endDate: "25 Apr, 2025", duration: "1 week"},
    {id: 6, opp: "Classes resume",beginDate:"26 Apr, 2025",endDate: "06 June, 2025", duration: "6 weeks"},
    {id: 7, opp: "Eid Al-adha vacation",beginDate:"07 June, 2025",endDate: "13 June, 2025", duration: "1 week"},
    {id: 8, opp: "Final exam period",beginDate:"14 June, 2025",endDate: "20 June, 2025", duration: "1 week"},
    {id: 9, opp: "Oral exam period",beginDate:"21 June, 2025",endDate: "27 June, 2025", duration: "1 week"},
    {id: 10, opp: "Re-exam period",beginDate:"28 June, 2025",endDate: "4 July, 2025", duration: "1 week"},
    {id: 11, opp: "Publication of re-exam results",beginDate:"5 July, 2025",endDate: "", duration: ""},
    {id: 13, opp: "Summer break begin",beginDate:"05 July, 2025",endDate: "", duration: ""},
    {id: 14, opp: "University transfere application term",beginDate:"05 July, 2025",endDate: "31 Aug, 2024", duration: "8 weeks"},
    {id: 15, opp: "Class start 2025 - 2026",beginDate:"20 Sep, 2025",endDate: "", duration: ""},
]